<template>
  <v-container>
    <v-card class="px-5 py-3">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :single-select="true"
        :items-per-page="10"
        loading-text="Идёт загрузка данных..."
        item-key="id"
        :loading="loading"
        style="cursor: pointer"
      >
        <template v-slot:[`item.index`]="{ item }">
          {{ items.indexOf(item) + 1 }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editName(item)">
            mdi-pencil
          </v-icon>
          <v-icon small color="red" class="mr-2" @click="deleteName(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-btn color="primary" class="ma-3" @click="create"> Добавить </v-btn>
    </v-card>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="headline"> Название </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="foodName.name"
            label="Введите название"
            dense
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Назад </v-btn>
          <v-btn color="primary" text @click="saveFood" :disabled="disabledBtn">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="subplacedialog" max-width="600">
      <v-card>
        <v-card-title class="headline"> Редактировать название </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="foodName.name"
            label="Введите новое название"
            dense
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="subplacedialog = false">
            Отмена
          </v-btn>
          <v-btn
            color="primary"
            @click="newFoodName"
            text
            :disabled="disabledBtn"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "Location",
  data: () => ({
    disabledBtn: false,
    foodName: {
      id: null,
      name: null
    },
    dialog: false,
    subplacedialog: false,
    selected: [],
    loading: true,
    headers: [
      { text: "№", value: "index", class: "table-title" },
      {
        text: "Название",
        value: "name",
        sortable: false,
        class: "table-title"
      },
      {
        text: "Редактировать",
        value: "actions",
        sortable: false,
        class: "table-title"
      }
    ],
    items: []
  }),
  created() {
    this.loadRow();
  },
  methods: {
    deleteName(item) {
      window.axios.delete(`/api/task/type/${item.id}/`).then(res => {
        this.loadRow();
      });
    },
    editName(item) {
      this.foodName = item;
      this.subplacedialog = true;
    },
    newFoodName() {
      this.disabledBtn = true;
      window.axios
        .put(`/api/task/type/${this.foodName.id}/`, {
          name: this.foodName.name,
          company: localStorage.getItem("company_id")
        })
        .then(resp => {
          this.disabledBtn = false;
          this.subplacedialog = false;
          this.loadRow();
        });
    },
    saveFood() {
      this.disabledBtn = true;
      window.axios
        .post("/api/task/type/create/", {
          name: this.foodName.name,
          company: localStorage.getItem("company_id")
        })
        .then(resp => {
          this.disabledBtn = false;
          this.dialog = false;
          this.loadRow();
        });
    },
    loadRow() {
      this.items = [];
      window.axios
        .get(`/api/task/type/list/${localStorage.getItem("company_id")}/`)
        .then(response => {
          this.items = response.data;
          this.loading = false;
        });
    },
    create() {
      this.dialog = true;
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
</style>
